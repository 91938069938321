body, html{
    overflow: hidden;
    font-weight: 400!important;
    min-height: 100%;
    scroll-behavior: smooth;
    width: 100%;
    max-width: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

*:focus{
    outline: none;
}

.parent, #onceadev{
    width: 100%;
    height: 100%;
}

.logo{
    width: 120px;
    align-self: center;
}

.login-cover{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.end-meeting, .load-meeting{
    height: 100vh;
    width: 100vw;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.login-input-div{
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 30px;
    min-width: 300px;
    min-height: 350px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(13,142,0,0.5);
    -moz-box-shadow: 0px 0px 30px 0px rgba(13,142,0,0.5);
    box-shadow: 0px 0px 30px 0px rgba(13,142,0,0.5);
    justify-content: center;
}

.login-input-div label{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}

.login-input-div input{
    height: 30px;
    font-size: 14px;
    padding: 2px 4px;
}

.login-input-div button{
    height: 40px;
    font-weight: 500;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    background-color: transparent;
    background-image: linear-gradient(190deg, #18EE00 0%, #13C400 58%);
    color: white;
    border-style: none;
    border-radius: 100px;
    transition: all ease-in 500s;
    cursor: pointer;
}

.end-meeting{
    position: 'fixed';
    top: 0;
    left: 0;
    width: '100%';
    height: '100%';
    background: 'green';
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    font-size: '24px';
    color: 'white';
    z-index: '10000';
}